@import '../../styles/main';

.c-PremiumBreakdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__accordionLabel {
    color: $color-primary;
    font-size: 16px;
    font-weight: $ft-w-bold;
    line-height: 1.25;
  }

  &__customAccordionContainer {
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    padding: 0 24px;
  }

  &__heading {
    @include premiumBreakdownFont;

    color: $color-primary;
    font-weight: $ft-w-bold;
    margin-bottom: $small-margin;
    padding-top: $medium-padding;
    text-align: left;
  }

  &__headingNoPadding {
    @include premiumBreakdownFont;

    color: $color-primary;
    font-weight: $ft-w-bold;
    margin-bottom: $small-margin;
    text-align: left;
  }

  &__section {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    padding: $medium-padding $light-padding;
  }

  &__noPaddingAccordion {
    @include noPaddingCardContainer;
  }

  &__label {
    color: $tertiary-text-color;
    font-size: 14px;
    padding-top: 2%;
    text-align: left;
  }

  &__breakdownLinktext {
    @include premiumBreakdownFont;

    text-align: left;
  }

  &__rowPadding {
    padding-top: 20px;
  }
}
